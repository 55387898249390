import { Grid, List, ListItemText, ListItem as MuiListItem } from "@mui/material";

import styled from "@emotion/styled";

import { getBuildVersion } from "../../utilities/common";

const Wrapper = styled.div`
    padding: ${(props) => props.theme.spacing(1) / 4} ${(props) => props.theme.spacing(4)};
    background: ${(props) => props.theme.palette.common.white};
    position: relative;
`;

const ListItem = styled(MuiListItem)`
    display: inline-block;
    width: auto;
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};

    &,
    &:hover,
    &:active {
        color: #000;
    }
`;

function Footer() {
    return (
        <Wrapper>
            <Grid container spacing={0}>
                <Grid container item xs={12} md={12} justifyContent="center">
                    <List>
                        <ListItem>
                            <ListItemText primary={`© ${new Date().getFullYear()} - Licentiebeheer`} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={"|"} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={getBuildVersion()} />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Wrapper>
    );
}

export default Footer;
