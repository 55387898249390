import { UPSERT_LICENSEMODULES } from "../../constants/redux";
import api from "../../services/api/apiService";
import { handleErrors } from "../../utilities/helpers";

// haal alle licentie modules op
export const fetchLicenseModules = (licensesid) => async (dispatch) => {
    try {
        const response = await api.get(`licenses/modules`, {
            licensesid: licensesid,
        });

        dispatch({
            type: UPSERT_LICENSEMODULES,
            payload: response.data.value,
            // maak unieke id
            licensesid: Number(licensesid),
        });
    } catch (error) {
        dispatch(handleErrors(error));
    }
};
