import { getBaseURL } from "../store/actions/authActions";

// return the token from the session storage
export const getToken = () => {
    try {
        const oauth = JSON?.parse(sessionStorage.getItem("app_oauth"));
        return oauth?.access_token || null;
    } catch (_error) {
        return null;
    }
};

// return the refresh token from the session storage
export const getRefreshToken = () => {
    try {
        const oauth = JSON?.parse(sessionStorage.getItem("app_oauth"));
        return oauth?.refresh_token || null;
    } catch (_error) {
        return null;
    }
};

// return the searchbutton optionid from the session storage
export const getSearchOption = () => {
    return sessionStorage.getItem("searchOption") || 0;
};

// remove the token from the session storage
export const removeToken = () => {
    sessionStorage.removeItem("token");
};

// update the token from the session storage
export const setToken = (utoken) => {
    sessionStorage.setItem("app_oauth", utoken);
};

// set the searchbutton option id from the session storage
export const setSearchOption = (id) => {
    sessionStorage.setItem("searchOption", id);
};

export const getBuildVersion = () => {
    return process.env.REACT_APP_REVISION || "development 1.0";
};
export async function getProtserver() {
    const protserver = await getBaseURL();
    return protserver;
}

export const getURL = (endpoint) => {
    return `${process.env.REACT_APP_API_URL || localhostUrl}${endpoint}/`;
};

export const localhostUrl = `http://${process.env.REACT_APP_LOCALIPADDRESS || "localhost"}:9998/licserver/`;
