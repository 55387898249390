import { Outlet } from "react-router-dom";

import { CssBaseline, useTheme } from "@mui/material";

import { Global, css } from "@emotion/react";
import styled from "@emotion/styled";

const globalStyle = (props) => css`
    html,
    body,
    #root {
        height: 100%;
    }

    body {
        background: ${props.theme.body.background};
    }
`;

const Root = styled.div`
    max-width: 520px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    display: flex;
    min-height: 100%;
`;

function Auth() {
    const theme = useTheme();

    return (
        <Root>
            <CssBaseline />
            <Global styles={globalStyle({ theme })} />
            {/* This will render the children */}
            <Outlet />
        </Root>
    );
}

export default Auth;
