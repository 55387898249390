import { Clipboard, Grid } from "react-feather";

import async from "../components/standardComponents/Async";

const ModulesPage = async(() => import("../pages/definitions/index"), "modulesPage");
const LicensePage = async(() => import("../pages/licenses/main/License"), "licensepage");
const DetailsPage = async(() => import("../pages/licenses/details/index"), "detLailspage");
const LandingPage = async(() => import("../pages/home/LandingPage"), "landingpage");
const HomePage = async(() => import("../pages/home/DefaultHome"), "homepage");
const PageRenew = async(() => import("../services/auth/RenewSession"), "pagerenew");
const ModuleNewDialog = async(() => import("../pages/definitions/Create"), "modulenewdialog");
const ModuleEditDialog = async(() => import("../pages/definitions/Update"), "moduleeditdialog");

const RenewRoutes = {
    id: "Renew",
    path: "/renewsession",
    component: PageRenew,
    name: "Renew",
    icon: null,
    children: null,
};
//Sidenav componenten
const LandingRoutes = {
    id: "Landing",
    path: "/login/:accessToken/:refreshToken/:licensesid",
    component: LandingPage,
    name: "Landing",
    //containsHome: true,
    children: null,
};

const HomeRoutes = {
    id: "Home",
    path: "/",
    component: HomePage,
    name: "Home",
    containsHome: true,
    children: null,
};

const licenseDetailsRoutes = {
    id: "Details",
    path: "license_details/:licenseesId/:licensesName/:licensesId",
    component: DetailsPage,
    children: null,
};

const LicenseWithSearchRoutes = {
    id: "Licenties",
    path: "license?search=:searchterm",
    name: "License",
    icon: <Clipboard />,
    component: LicensePage,
    children: null,
};

const LicenseRoutes = {
    id: "Licenties",
    path: "/licenses",
    name: "Licenses",
    icon: <Clipboard />,
    component: LicensePage,
    children: [LicenseWithSearchRoutes],
};

const DefinitionRoutes = {
    id: "Modules",
    path: "/modules",
    name: "Modules",
    icon: <Grid />,
    component: ModulesPage,
    children: [
        {
            id: "Licenties",
            path: ":action/:item",
            name: "License",
            icon: null,
            component: ModuleNewDialog,
            children: null,
        },
        {
            id: "Licenties",
            path: "edit/:action/:id",
            name: "License",
            icon: null,
            component: ModuleEditDialog,
            children: null,
        },
    ],
};

export const privateRoutes = [LicenseRoutes, DefinitionRoutes, licenseDetailsRoutes];
export const publicRoutes = [HomeRoutes, RenewRoutes, LandingRoutes];
