import { useState } from "react";
import { useDispatch } from "react-redux";

import { Info } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";

import { mainColor } from "../../constants/colors";
import { LOGOUT } from "../../constants/redux";
import { getBaseURL } from "../../store/actions";
import { useAuth } from "./AuthProvider";

function RenewSession() {
    const [open, setOpen] = useState(true);
    const dispatch = useDispatch();
    const { setAuth } = useAuth();
    const handleRenew = async () => {
        const protserver = await getBaseURL("frontend");

        setOpen(false);
        dispatch({
            type: LOGOUT,
        });
        setAuth({});
        if (sessionStorage.getItem("app_user_licenses")) {
            const lic = sessionStorage.getItem("app_user_license");
            sessionStorage.clear();
            window.location.href = `${protserver}app/ELM/${lic}/0`;
        } else {
            window.location.href = `${protserver}`;
        }
    };
    return (
        <Dialog id="sessionDialog" maxWidth="sm" open={open}>
            <DialogTitle
                style={{
                    backgroundColor: mainColor,
                    color: "white",
                }}
            >
                Sessie Beëindigd!
            </DialogTitle>
            <DialogContent dividers>
                <Grid container alignItems="center">
                    <Grid item xs={1}>
                        <Info color="primary" fontSize="large" />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography>
                            Er is een time-out opgetreden vanwege inactiviteit of een verlopen sessie. Log opnieuw in om
                            verder te gaan.
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="primary" onClick={() => handleRenew()}>
                    Login
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default RenewSession;
