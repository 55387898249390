import { colorYellow } from "../constants/colors";

const overrides = {
    MuiCardHeader: {
        styleOverrides: {
            action: {
                marginTop: "-4px",
                marginRight: "-4px",
            },
        },
    },
    MuiPickersDay: {
        styleOverrides: {
            day: {
                fontWeight: "300",
            },
        },
    },
    MuiPickersYear: {
        styleOverrides: {
            root: {
                height: "64px",
            },
        },
    },
    MuiPickersCalendar: {
        styleOverrides: {
            transitionContainer: {
                marginTop: "6px",
            },
        },
    },
    MuiPickersCalendarHeader: {
        styleOverrides: {
            iconButton: {
                backgroundColor: "transparent",
                "& > *": {
                    backgroundColor: "transparent",
                },
            },
            switchHeader: {
                marginTop: "2px",
                marginBottom: "4px",
            },
        },
    },
    MuiPickersClock: {
        styleOverrides: {
            container: {
                margin: `32px 0 4px`,
            },
        },
    },
    MuiPickersClockNumber: {
        styleOverrides: {
            clockNumber: {
                left: `calc(50% - 16px)`,
                width: "32px",
                height: "32px",
            },
        },
    },
    MuiPickerDTHeader: {
        styleOverrides: {
            dateHeader: {
                "& h4": {
                    fontSize: "2.125rem",
                    fontWeight: 400,
                },
            },
            timeHeader: {
                "& h3": {
                    fontSize: "3rem",
                    fontWeight: 400,
                },
            },
        },
    },
    MuiPickersTimePicker: {
        styleOverrides: {
            hourMinuteLabel: {
                "& h2": {
                    fontSize: "3.75rem",
                    fontWeight: 300,
                },
            },
        },
    },
    MuiPickersToolbar: {
        styleOverrides: {
            toolbar: {
                "& h4": {
                    fontSize: "2.125rem",
                    fontWeight: 400,
                },
            },
        },
    },
    MuiCssBaseline: {
        styleOverrides: {
            "*::-webkit-scrollbar": {
                width: "7px",
                height: "7px",
            },
            "*::-webkit-scrollbar-track": {
                //background: 'inherit',
                //boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
            },
            "*::-webkit-scrollbar-thumb": {
                backgroundColor: colorYellow,
                borderRadius: "20px",
                border: "inherit",
            },
            "*::-webkit-scrollbar-corner": {
                background: "inherit",
            },
        },
    },
};

export default overrides;
