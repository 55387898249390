import { SUCCESS } from "../../constants/redux";
import api from "../../services/api/apiService";
import { handleErrors } from "../../utilities/helpers";
import { fetchLicenseVersions } from "./licenseVersionsActions";

// licapps toevoegen/wijzigen
export const updateLicapps = (apps, succesmelding) => async (dispatch) => {
    try {
        const updateResponse = await api.post(`licenses/licapps`, apps);
        // toon succesmelding
        succesmelding &&
            dispatch({
                type: SUCCESS,
                payload: "Licapp gewijzigd " + updateResponse.data.value,
            });
        // haal alle versies bij specifieke licentie op
        dispatch(fetchLicenseVersions(apps.licensesid));
    } catch (error) {
        dispatch(handleErrors(error));
    }
};

// licapps verwijderen
export const deleteLicapps = (id, license) => async (dispatch) => {
    try {
        const deleteResponse = await api.delete(`licenses/licapps`, {
            licappsid: id,
            permanently: true,
        });
        // toon succesmelding
        dispatch({
            type: SUCCESS,
            payload: "Licapp verwijderd" + deleteResponse.data.value,
        });
        // haal alle versies bij specifieke licentie op
        dispatch(fetchLicenseVersions(license));
    } catch (error) {
        dispatch(handleErrors(error));
    }
};
