import { groupBy } from "lodash";
import { createSelector } from "redux-orm";

import scheme from "../models/orm";

// licensesversions
export const selectAllLicenseVersions = createSelector(scheme, (orm) => {
    return groupBy(
        orm.Licversions.all()
            .toRefArray()
            .map(({ licensesid, licversionsid, code, description, tenantid, lastlogin, deleted, licapps }) => ({
                licensesid: licensesid,
                licversionsid: licversionsid,
                code: code,
                description: description,
                tenantid: tenantid,
                lastlogin: lastlogin,
                licapps: licapps,
                deleted: deleted,
                licappslength: licapps.length,
            })),
        (it) => it.licensesid,
    );
});
