import { SUCCESS } from "../../constants/redux";
import api from "../../services/api/apiService";
import { handleErrors } from "../../utilities/helpers";
import { fetchDefmodulegroups } from "./defModuleGroupsActions";

// toevoegen van een nieuw defmodule
export const createDefmodules = (module) => async (dispatch) => {
    try {
        const createResponse = await api.post(`definitions/defmodules`, module);
        // toon succesmelding
        dispatch({
            type: SUCCESS,
            payload: "Module toegevoegd " + createResponse.data.value,
        });
        // ophalen van de modulegroepen
        dispatch(fetchDefmodulegroups());
    } catch (error) {
        dispatch(handleErrors(error));
    }
};

// defmodules wijzigen
export const updateDefmodules = (modules) => async (dispatch) => {
    try {
        const updateResponse = await api.post(`definitions/}defmodules`, modules);
        // haal alle defmodules
        dispatch(fetchDefmodulegroups());
        // toon succesmelding
        dispatch({
            type: SUCCESS,
            payload: "Module gewijzigd " + updateResponse.data.value,
        });
    } catch (error) {
        dispatch(handleErrors(error));
    }
};

// verwijderd defmodules
export const deleteDefmodules = (id) => async (dispatch) => {
    try {
        await api.delete(`definitions/defmodules`, { defmodulesid: id });

        // toon succesmelding
        dispatch({
            type: SUCCESS,
            payload: "Module verwijderd " + id,
        });
        dispatch(fetchDefmodulegroups());
    } catch (error) {
        dispatch(handleErrors(error));
    }
};
