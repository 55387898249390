import React from "react";

import { Menu as MenuIcon } from "@mui/icons-material";
import { Grid, Hidden, AppBar as MuiAppBar, IconButton as MuiIconButton, Toolbar, Typography } from "@mui/material";

import styled from "@emotion/styled";

const AppBar = styled(MuiAppBar)`
    background: ${(props) => props.theme.header.background};
    color: ${(props) => props.theme.header.color};
    box-shadow: ${(props) => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
    svg {
        width: 22px;
        height: 22px;
    }
`;

const Header = ({ onDrawerToggle }) => (
    <React.Fragment>
        <AppBar position="sticky" elevation={0}>
            <Toolbar>
                <Grid container alignItems="center">
                    {/* in mobiele modus verberg de sidebar en toon de menu knop */}
                    <Hidden mdUp>
                        <Grid item>
                            <IconButton color="inherit" aria-label="Open drawer" onClick={onDrawerToggle}>
                                <MenuIcon />
                            </IconButton>
                        </Grid>
                    </Hidden>
                    <Hidden mdUp>
                        <Grid item>
                            <Typography variant="h6" color="primary">
                                Licentiebeheer{" "}
                            </Typography>
                        </Grid>
                    </Hidden>
                    <Hidden mdDown>
                        <Grid item>
                            <Typography variant="h3" color="inherit">
                                Licentiebeheer
                            </Typography>
                        </Grid>
                    </Hidden>
                    <Grid item xs />
                    <Grid item></Grid>
                    <Grid item></Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    </React.Fragment>
);

export default Header;
