// https://www.youtube.com/watch?v=nI8PYZNFtac&list=PL0Zuz27SZ-6PRCpm9clX0WiBEMB70FWwd&index=4
import { useEffect } from "react";

import { axiosInstance } from "../services/api/apiService";
import { useAuth } from "../services/auth/AuthProvider";
import useRefreshToken from "./useRefreshToken";

let refreshingPromise;

const useInterceptors = ({ children }) => {
    const refresh = useRefreshToken();
    const { auth } = useAuth();

    useEffect(() => {
        const requestIntercept = axiosInstance.interceptors.request.use(
            (config) => {
                // dit is de initiele request
                if (!config.headers["Authorization"]) {
                    config.headers["Authorization"] = `Bearer ${auth.access_token}`;
                }
                return config;
            },
            (err) => Promise.reject(err),
        );

        const reponseIntercept = axiosInstance.interceptors.response.use(
            (response) => response,
            async (error) => {
                const prevRequest = error?.config;
                // hier moet nog evt de andere statussen komen
                if (auth.access_token) {
                    if (error?.response?.status === 401 && !prevRequest?.sent) {
                        if (
                            String(error.response?.data?.error?.code) === "-1011201" ||
                            String(error.response?.data?.error?.code) === "-1011202" ||
                            String(error.response?.data?.error?.code) === "-1011204" ||
                            String(error.response?.data?.error?.code) === "-1011203"
                        ) {
                            // om te zorgen dat je niet in een eeuwig loop komt dus alleen maar 1x proberen
                            prevRequest.sent = true;
                            try {
                                if (!refreshingPromise) {
                                    refreshingPromise = refresh();
                                }
                                const token = await refreshingPromise;
                                // update request met nieuwe token en opnieuw versturen
                                prevRequest.headers["Authorization"] = `Bearer ${token?.access_token}`;
                                return axiosInstance(prevRequest);
                            } catch (_err) {
                                window.location.href = `/renewsession`;
                            } finally {
                                refreshingPromise = null;
                            }
                        } else {
                            window.location.href = `/renewsession`;
                        }
                    }
                }
                // return overige error
                return Promise.reject(error);
            },
        );

        // cclean up function
        return () => {
            axiosInstance.interceptors.response.eject(requestIntercept);
            axiosInstance.interceptors.response.eject(reponseIntercept);
        };
    }, [auth, refresh]);

    return children;
};
export default useInterceptors;
