import { createSelector } from "redux-orm";

import scheme from "../models/orm";

export const selectAllLicensees = createSelector(scheme, (session) => {
    return session?.Licensees?.all()
        ?.toRefArray()
        ?.sort((a, b) => {
            if (a.description.toUpperCase() < b.description.toUpperCase()) {
                return -1;
            }
            if (a.description.toUpperCase() > b.description.toUpperCase()) {
                return 1;
            }
            return 0;
        });
});

export const selectLicenseesById = createSelector(
    scheme,
    (state, _licenseesid) => selectAllLicensees(state),
    (_state, licenseesid) => licenseesid,
    (_state, licensees, licenseesid) => {
        return licensees?.find((l) => l.licenseesid === licenseesid);
    },
);
