import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import { Box, CircularProgress, StyledEngineProvider, ThemeProvider } from "@mui/material";

import { jwtDecode } from "jwt-decode";

import Snackbars from "./components/SnackbarMelding";
import UseInterceptors from "./hooks/useInterceptors";
import useRefreshToken from "./hooks/useRefreshToken";
import AuthLayout from "./layouts/Auth";
import Routes from "./routes/index";
import { useAuth } from "./services/auth/AuthProvider";
import RenewSession from "./services/auth/RenewSession";
import { getBaseURL } from "./store/actions";
import "./styles/App.css";
import maTheme from "./theme";
import { getRefreshToken } from "./utilities/common";

function App({ theme }) {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const { auth, setAuth } = useAuth();
    const refresh = useRefreshToken();
    const getPosition = (token, index) => {
        return token.split("/", index).join("/").length;
    };

    useEffect(() => {
        // eerste keer bij landing
        if (!getRefreshToken()) {
            const params = window.location;
            const tokens = params.pathname.substring(7);
            const accessToken = tokens.substring(0, getPosition(tokens, 1));
            const refreshToken = tokens.substring(getPosition(tokens, 1) + 1, getPosition(tokens, 2));
            const license = tokens.substring(getPosition(tokens, 2) + 1, getPosition(tokens, 3));

            try {
                // controleer of url bevat een gelidige jwt die bestaat uit 3 gedeelte bijv. 'eyJ0eXAiOiJKV1Qi........'
                if (jwtDecode(accessToken)) {
                    license && sessionStorage.setItem("app_user_license", license);

                    // voor development
                    if (process.env.REACT_APP_API_URL === undefined) {
                        // gebruikt locale token in constante als dit niet in url zit
                        if (accessToken !== "" && refreshToken !== "") {
                            setAuth({
                                access_token: accessToken,
                                refresh_token: refreshToken,
                            });
                            sessionStorage.setItem(
                                "app_oauth",
                                JSON.stringify({
                                    access_token: accessToken,
                                    refresh_token: refreshToken,
                                }),
                            );
                        }
                    } else {
                        // haal de params uit de url en opslaan in de sessionStorage
                        setAuth({ access_token: accessToken, refresh_token: refreshToken });
                        sessionStorage.setItem(
                            "app_oauth",
                            JSON.stringify({
                                access_token: accessToken,
                                refresh_token: refreshToken,
                            }),
                        );
                    }
                }
            } catch (_error) {
                // bij ongeldige tokens redirecten naar inlogscherm
                async function getProtserverURL() {
                    try {
                        const url = await getBaseURL("", "APM");
                        // redirect naar protserver
                        window.location.href = `${url.frontend_url}`;
                    } catch (_err) {
                        setError(true);
                    }
                }
                getProtserverURL();
            } finally {
                if (auth) {
                    setIsLoading(false);
                }
            }
        } // bij browser refreshen
        else {
            // controleer of de jwt nog geldig is; is sessie nog actief
            // als dit wel het geval is settings ophalen, gebruikers opnieuw laten inloggen
            async function handleRefresh() {
                try {
                    await refresh();
                    setIsLoading(false);
                } catch (_err) {
                    setError(true);
                }
            }
            handleRefresh();
        }
    }, []);

    return (
        <Fragment>
            <Helmet titleTemplate="%s | Licentiebeheer" defaultTitle="Licentiebeheer" />
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={maTheme[theme.currentTheme]}>
                    <UseInterceptors>
                        <Snackbars />
                        {isLoading ? (
                            !error ? (
                                <AuthLayout>
                                    <Box>
                                        <CircularProgress /> <p>Loading...</p>
                                    </Box>
                                </AuthLayout>
                            ) : (
                                <RenewSession />
                            )
                        ) : (
                            <Routes />
                        )}
                    </UseInterceptors>
                </ThemeProvider>
            </StyledEngineProvider>
        </Fragment>
    );
}

export default connect((store) => ({ theme: store.themeReducer }))(App);
