import { createSelector } from "redux-orm";

import scheme from "../models/orm";

export const selectAllDefversiontype = createSelector(scheme.Defversiontypes);

export const selectDefversiontypesByDefappsid = createSelector(
    scheme,
    (state, _defappsid) => selectAllDefversiontype(state),
    (_state, defappsid) => defappsid,
    (_state, defversiontypes, defappsid) => {
        return defversiontypes
            ?.filter((type) => type.defappsid === defappsid)
            ?.sort((a, b) => {
                if (a.description.toUpperCase() < b.description.toUpperCase()) {
                    return -1;
                }
                if (a.description.toUpperCase() > b.description.toUpperCase()) {
                    return 1;
                }
                return 0;
            });
    },
);

export const selectDefversiontypesByDeflictypesId = createSelector(
    scheme,
    (state, _deflictypesid) => selectAllDefversiontype(state),
    (_state, deflictypesid) => deflictypesid,
    (_state, defversiontypes, deflictypesid) => {
        return defversiontypes?.find((type) => type.deflictypesid === deflictypesid);
    },
);
