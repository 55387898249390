import { Navigate, Outlet, Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Page404 from "../components/standardComponents/Page404";
import AuthLayout from "../layouts/Auth";
import DashboardLayout from "../layouts/Dashboard";
import { useAuth } from "../services/auth/AuthProvider";
import { privateRoutes, publicRoutes } from "./routes";

const renderRoutes = (routes) =>
    routes.map(({ children, path, component: Component, id }) => {
        return (
            <Route key={id} path={path} element={<Component />}>
                {children && renderRoutes(children)}
            </Route>
        );
    });
// PrivateRoutes token nodig om bij de pagina te komen
const ProtectedRoutes = ({ children }) => {
    const { auth } = useAuth();
    const hasAccess = auth.access_token;

    if (!hasAccess) {
        return <Navigate to="/" replace />;
    }

    return children ? children : <Outlet />;
};

const NavigationRoutes = () => (
    <Router future={{ v7_startTransition: true }}>
        <Routes>
            <Route element={<AuthLayout />}>{renderRoutes(publicRoutes)}</Route>
            <Route element={<DashboardLayout />}>
                {/* Routes that require access_token for Dashboard */}
                <Route element={<ProtectedRoutes />}>{renderRoutes(privateRoutes)}</Route>
            </Route>
            {/* Fallback route for 404 */}
            <Route
                path="*"
                render={() => (
                    <AuthLayout>
                        <Page404 />
                    </AuthLayout>
                )}
            />
        </Routes>
    </Router>
);

export default NavigationRoutes;
