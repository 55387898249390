import { Model, attr } from "redux-orm";

import { CREATE_MODULES, DELETE_MODULES, RESET_MODULES, UPDATE_MODULES, UPSERT_MODULES } from "../../constants/redux";

class Modules extends Model {
    static reducer(action, modules) {
        switch (action.type) {
            case CREATE_MODULES:
                modules.create(action.payload);
                break;
            case UPSERT_MODULES:
                action.payload.forEach((module) => {
                    modules.upsert({
                        ...module,
                        uid: Number(String(module.licappsid) + String(module.defmodulesid)),
                        licversionid: action.licversionid,
                    });
                });
                break;
            case UPDATE_MODULES:
                modules.withId(action.payload.uid).update(action.payload);
                break;
            case DELETE_MODULES:
                modules.withId(action.payload).delete();
                break;
            case RESET_MODULES:
                modules.delete();
                break;
            default:
                break;
        }
    }
}
Modules.modelName = "Modules";

Modules.options = {
    //idAttribute: "licappsid" + "-" + "defmodulesid", // default idd
    idAttribute: "uid", // default idd ==> Number(String(modules.licappsid) + String(modules.defmodulesid))
};
// attributen
Modules.fields = {
    uid: attr(),
    $id: attr(),
    activationdate: attr(),
    code: attr(),
    defmodulesid: attr(),
    defoptionsid: attr(),
    expdate: attr(),
    exptestdate: attr(),
    licappsid: attr(),
    licensesid: attr(),
    licversionsid: attr(),
    value: attr(),
};

export default Modules;
