import { ORM } from "redux-orm";

import Admins from "./adminsModel";
import Defapps from "./defappsModel";
import Defmodulegroups from "./defmodulegroupsModel";
import Defoptions from "./defoptionsModel";
import Defversiontypes from "./defversiontypesModel";
import LicenseModules from "./licenseModulesModel";
import LicenseTypes from "./licenseTypesModel";
import Licensees from "./licenseesModel";
import Licenses from "./licensesModel";
import Licversions from "./licversionsModel";
import Modules from "./modulesModel";

const orm = new ORM({
    stateSelector: (state) => state.scheme,
});
orm.register(
    Licensees,
    Licenses,
    Licversions,
    Modules,
    Defapps,
    Defmodulegroups,
    Defversiontypes,
    Admins,
    LicenseModules,
    LicenseTypes,
    Defoptions,
);

export default orm;
