import { Model, attr } from "redux-orm";

import {
    CREATE_LICENSES,
    DELETE_LICENSES,
    RESET_LICENSES,
    UPDATE_LICENSES,
    UPSERT_LICENSES,
} from "../../constants/redux";

class Licenses extends Model {
    static reducer(action, licenses) {
        switch (action.type) {
            case CREATE_LICENSES:
                licenses.create(action.payload);
                break;
            case UPSERT_LICENSES:
                action.payload.forEach((element) => {
                    licenses.upsert(element);
                });
                break;
            case UPDATE_LICENSES:
                licenses.withId(action.payload.licenseesid).update(action.payload);
                break;
            case DELETE_LICENSES:
                licenses.withId(action.payload).delete();
                break;
            case RESET_LICENSES:
                licenses.delete();
                break;
            default:
                break;
        }
    }
}
Licenses.modelName = "Licenses";

Licenses.options = {
    idAttribute: "licenseesid", // default idd
};
// attributen
Licenses.fields = {
    $id: attr(),
    licenseesid: attr(),
    customercode: attr(),
    description: attr(),
    licenses: attr(),
};

export default Licenses;
