import { useState } from "react";
import { Outlet } from "react-router-dom";

import { CssBaseline, Paper as MuiPaper, useMediaQuery, useTheme } from "@mui/material";
import { spacing } from "@mui/system";

import { Global, css } from "@emotion/react";
import styled from "@emotion/styled";

import Footer from "../components/standardComponents/Footer";
import Header from "../components/standardComponents/Header";
import Sidebar from "../components/standardComponents/Sidebar";
import { IsMobile } from "../utilities/helpers";

const globalStyle = (props) => css`
    html,
    body,
    #root {
        height: 100%;
    }

    body {
        background: ${props.theme.body.background};
    }

    .MuiCardHeader-action .MuiIconButton-root {
        padding: 4px;
        width: 28px;
        height: 28px;
    }
`;

const Root = styled.div`
    display: flex;
    min-height: 100vh;
`;

const AppContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);
// Define a function for MainContent to handle props-based CSS
const mainContentStyle = (props) => css`
    flex: 1;
    background: ${props.theme.body.background};

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        flex: none;
    }

    .MuiPaper-root .MuiPaper-root {
        box-shadow: none;
    }
`;

const MainContent = styled(Paper)`
    ${(props) => mainContentStyle(props)}
`;

const Dashboard = ({ routes }) => {
    const theme = useTheme();
    const isMobile = IsMobile();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
    const [mobileOpen, setMobileOpen] = useState(false);
    const [expanded] = useState(true);
    const drawerWidth = expanded ? 97 : 70;

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const defaultDrawerProps = {
        routes: routes,
        anchor: "left",
        onClose: handleDrawerToggle,
        // handleExpand: () => setExpanded(!expanded),
        expanded: expanded,
        sx: {
            width: drawerWidth,
            flexShrink: 0,
        },
    };

    return (
        <Root>
            <CssBaseline />
            <Global styles={globalStyle({ theme })} />

            {isMobile ? (
                <Sidebar variant="temporary" open={mobileOpen} {...defaultDrawerProps} />
            ) : (
                <Sidebar variant="permanent" {...defaultDrawerProps} />
            )}

            <AppContent>
                <Header onDrawerToggle={handleDrawerToggle} />
                <MainContent p={isLargeScreen ? 10 : 5}>
                    <Outlet />
                </MainContent>
                <Footer />
            </AppContent>
        </Root>
    );
};

export default Dashboard;
