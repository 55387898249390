import { useEffect, useState } from "react";

import Loader from "./Loader";

async function lazyWithRetry(componentImport, name) {
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem(`retry-${name}-refreshed`) || "false");
    try {
        const component = await componentImport();
        window.sessionStorage.setItem(`retry-${name}-refreshed`, "false");

        return component;
    } catch (error) {
        if (!hasRefreshed) {
            window.sessionStorage.setItem(`retry-${name}-refreshed`, "true");
            window.location.reload(); // Refresh the page
        } else {
            throw error; // Already refreshed once, throw error
        }
    }
}

export default function asyncComponent(componentImport, name) {
    return function AsyncComponentWrapper(props) {
        const [Component, setComponent] = useState(null);

        useEffect(() => {
            let isMounted = true; // Track if the component is still mounted
            const loadComponent = async () => {
                const { default: loadedComponent } = await lazyWithRetry(componentImport, name);
                if (isMounted) {
                    setComponent(() => loadedComponent); // Update the component state
                }
            };

            loadComponent();

            // Cleanup to prevent state updates after unmounting
            return () => {
                isMounted = false;
            };
        }, []);

        // If the component is loaded, render it, otherwise show the loader
        return Component ? <Component {...props} /> : <Loader />;
    };
}
