import { Model, attr } from "redux-orm";

import {
    CREATE_DEFVERSIONTYPES,
    DELETE_DEFVERSIONTYPES,
    RESET_DEFVERSIONTYPES,
    UPDATE_DEFVERSIONTYPES,
    UPSERT_DEFVERSIONTYPES,
} from "../../constants/redux";

class Defversiontypes extends Model {
    static reducer(action, defversiontypes) {
        switch (action.type) {
            case CREATE_DEFVERSIONTYPES:
                defversiontypes.create(action.payload);
                break;
            case UPSERT_DEFVERSIONTYPES:
                action.payload.forEach((versiontypes) => {
                    defversiontypes.upsert(versiontypes);
                });
                break;
            case UPDATE_DEFVERSIONTYPES:
                defversiontypes.withId(action.payload.deflictypesid).update(action.payload);
                break;
            case DELETE_DEFVERSIONTYPES:
                defversiontypes.withId(action.payload).delete();
                break;
            case RESET_DEFVERSIONTYPES:
                defversiontypes.delete();
                break;
            default:
                break;
        }
    }
}
Defversiontypes.modelName = "Defversiontypes";

Defversiontypes.options = {
    idAttribute: "deflictypesid", // default idd
};
// attributen
Defversiontypes.fields = {
    $id: attr(),
    defappsid: attr(),
    description: attr(),
    code: attr(),
    deflictypesid: attr(),
    defmodules: attr(),
};

export default Defversiontypes;
