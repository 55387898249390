import axios from "axios";

import { useAuth } from "../services/auth/AuthProvider";
import { getBaseURL } from "../store/actions";
import { getRefreshToken } from "../utilities/common";

const useRefreshToken = () => {
    const { auth, setAuth } = useAuth();

    const refresh = async () => {
        const backend = await getBaseURL("backend");
        const response = await axios.post(
            `${backend}refresh`,
            {},
            {
                headers: { Authorization: `Bearer ${auth.refresh_token ?? getRefreshToken()}` },
            },
        );
        // opslaan van de nieuwe token
        setAuth((prev) => {
            return { ...prev, ...response.data };
        });

        sessionStorage.setItem("app_oauth", JSON.stringify({ refresh_token: response.data.refresh_token }));

        return response.data;
    };

    return refresh;
};

export default useRefreshToken;
