import { CREATE_DEFAPPS, DELETE_DEFAPPS, SUCCESS, UPDATE_DEFAPPS, UPSERT_DEFAPPS } from "../../constants/redux";
import api from "../../services/api/apiService";
import { handleErrors } from "../../utilities/helpers";

// haal de evo-it apps op
export const fetchDefapps = () => async (dispatch) => {
    try {
        const response = await api.get(`definitions/defapps`);

        dispatch({
            type: UPSERT_DEFAPPS,
            payload: response.data.value,
        });
    } catch (error) {
        dispatch(handleErrors(error));
    }
};

// Toevoegen van een nieuwe evo-it app
export const createDefapps = (defapp) => async (dispatch) => {
    try {
        const createResponse = await api.post(`definitions/defapps`, defapp);
        const fetchResponse = await api.get(`definitions/defapps`);

        const newApp = fetchResponse.data.value.find((app) => app.defappsid === createResponse.data.value);

        dispatch({
            type: CREATE_DEFAPPS,
            payload: newApp,
        });
        // toon succesmelding
        dispatch({
            type: SUCCESS,
            payload: "App toegevoegd " + createResponse.data.value,
        });
    } catch (error) {
        dispatch(handleErrors(error));
    }
};

// wijzigen van een evo-it app
export const updateDefapps = (defapp) => async (dispatch) => {
    try {
        const updateResponse = await api.post(`definitions/defapps`, defapp);
        const fetchResponse = await api.get(`definitions/defapps`);

        const updatedApp = fetchResponse.data.value.find((app) => app.defappsid === updateResponse.data.value);

        dispatch({
            type: UPDATE_DEFAPPS,
            payload: updatedApp,
        });
        // toon succesmelding
        dispatch({
            type: SUCCESS,
            payload: "App gewijzigd " + updateResponse.data.value,
        });
    } catch (error) {
        dispatch(handleErrors(error));
    }
};

// evo-it app verwijderen
export const deleteDefapps = (id) => async (dispatch) => {
    try {
        await api.delete(`definitions/defapps`, { defappsid: id });

        dispatch({
            type: DELETE_DEFAPPS,
            payload: id,
        });
        // toon succesmelding
        dispatch({
            type: SUCCESS,
            payload: "App verwijderd " + id,
        });
    } catch (error) {
        // toon foutmelding
        dispatch(handleErrors(error));
    }
};
