import { DELETE_LICENSEES, RESET_LICENSEES, SUCCESS, UPSERT_LICENSEES } from "../../constants/redux";
import api from "../../services/api/apiService";
import { handleErrors } from "../../utilities/helpers";
import { createUpdateLicenses } from "./licensesActions";

// haal alle licentiehouders op
export const fetchLicensees = (searchString, option) => async (dispatch) => {
    try {
        const response = await api.get(`licenses/licensees`, {
            SearchString: searchString,
            Top: 5000,
            SearchFilter: option,
        });
        if (response.data.value.length !== 0) {
            dispatch({
                type: UPSERT_LICENSEES,
                payload: response.data.value,
            });
        } else {
            dispatch({
                type: RESET_LICENSEES,
            });
        }
    } catch (error) {
        dispatch(handleErrors(error));
    }
};

// licentiehouder verwijderen
export const deleteLicensees = (licenseesid, deleted) => async (dispatch) => {
    try {
        const deleteResponse = await api.delete(`licenses/licensees`, {
            licenseesid: licenseesid,
            permanently: deleted,
        });

        // verwijderd alleen de geselecteerde profiel in de orm
        dispatch({
            type: DELETE_LICENSEES,
            payload: licenseesid,
        });

        // toon sucessmelding
        dispatch({
            type: SUCCESS,
            payload: "Licentiehouder verwijderd " + licenseesid + deleteResponse.data.value,
        });
    } catch (error) {
        dispatch(handleErrors(error));
    }
};

// licentiehouder toevoegen/wijzigen
export const createUpdateLicensees = (licensees, license, version, apps, details, navigate) => async (dispatch) => {
    try {
        const response = await api.post(`licenses/licensees`, licensees);

        // // toon succesmelding
        // dispatch({
        //     type: SUCCESS,
        //     payload: "nieuwe licentiehouder toegevoegd " + response.data.value,
        // });
        // optie gelijk aan licentie, doorgaan met het vervolg proces
        if (license.keuze === "licentie") {
            dispatch(
                createUpdateLicenses(
                    { ...license, licenseesid: response.data.value },
                    version,
                    apps,
                    details,
                    navigate,
                ),
            );
        }
        // haal alle licentiehouders opnieuw op
        dispatch(fetchLicensees());
    } catch (error) {
        dispatch(handleErrors(error));
    }
};
