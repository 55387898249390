import { configureStore } from "@reduxjs/toolkit";

import rootReducer from "../reducers/index";

const store = configureStore({
    reducer: rootReducer,

    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    devTools: process.env.NODE_ENV !== "production", // Automatically includes Redux DevTools in non-production environments
});

export default store;
