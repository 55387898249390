import { Model, attr } from "redux-orm";

import { CREATE_DEFAPPS, DELETE_DEFAPPS, RESET_DEFAPPS, UPDATE_DEFAPPS, UPSERT_DEFAPPS } from "../../constants/redux";

class Defapps extends Model {
    static reducer(action, defapps) {
        switch (action.type) {
            case CREATE_DEFAPPS:
                defapps.create(action.payload);
                break;
            case UPSERT_DEFAPPS:
                action.payload.forEach((element) => {
                    defapps.upsert(element);
                });

                break;
            case UPDATE_DEFAPPS:
                defapps.withId(action.payload.defappsid).update(action.payload);
                break;
            case DELETE_DEFAPPS:
                defapps.withId(action.payload).delete();

                break;
            case RESET_DEFAPPS:
                defapps.delete();
                break;
            default:
                break;
        }
    }
}
Defapps.modelName = "Defapps";

Defapps.options = {
    idAttribute: "defappsid", // default idd
};
// attributen
Defapps.fields = {
    $id: attr(),
    defappsid: attr(),
    description: attr(),
    code: attr(),
};

export default Defapps;
