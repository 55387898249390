import { groupBy } from "lodash";
import { createSelector } from "redux-orm";

import scheme from "../models/orm";

export const selectAllLicenseTypes = createSelector(scheme.LicenseTypes);

export const selectAllLicenseTypesByLicensesId = createSelector(scheme, (orm) => {
    return groupBy(
        orm.LicenseTypes.all()
            .toRefArray()
            .sort((a, b) => {
                if (a.description.toUpperCase() < b.description.toUpperCase()) {
                    return -1;
                }
                if (a.description.toUpperCase() > b.description.toUpperCase()) {
                    return 1;
                }
                return 0;
            })
            .map(({ $id, defappsid, description, code, deflictypesid, uid, licensesid }) => ({
                $id: $id,
                defappsid: defappsid,
                description: description,
                code: code,
                deflictypesid: deflictypesid,
                uid: uid,
                licensesid: licensesid,
            })),
        (it) => it.licensesid,
    );
});

export const selectLicenseTypesByLicensesIdAndDefappsId = createSelector(
    scheme,
    (state, _licensesid, _defappsid) => selectAllLicenseTypes(state),
    (_state, licensesid, _defappsid) => licensesid,
    (_state, _licensesid, defappsid) => defappsid,
    (_state, licenseTypes, licensesid, defappsid) => {
        return licenseTypes?.filter((lt) => lt.licensesid === licensesid)?.find((app) => app.defappsid === defappsid);
    },
);
