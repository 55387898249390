import { Model, attr } from "redux-orm";

import {
    CREATE_LICENSEES,
    DELETE_LICENSEES,
    RESET_LICENSEES,
    UPDATE_LICENSEES,
    UPSERT_LICENSEES,
} from "../../constants/redux";

class Licensees extends Model {
    static reducer(action, licensees) {
        switch (action.type) {
            case CREATE_LICENSEES:
                licensees.create(action.payload);
                break;
            case UPSERT_LICENSEES:
                action.payload.forEach((element) => {
                    licensees.upsert(element);
                });
                break;
            case UPDATE_LICENSEES:
                licensees.withId(action.payload.licenseesid).update(action.payload);
                break;
            case DELETE_LICENSEES:
                licensees.withId(action.payload).delete();
                break;
            case RESET_LICENSEES:
                licensees.delete();
                break;
            default:
                break;
        }
    }
}
Licensees.modelName = "Licensees";

Licensees.options = {
    idAttribute: "licenseesid", // default idd
};
// attributen
Licensees.fields = {
    $id: attr(),
    licenseesid: attr(),
    code: attr(),
    description: attr(),
    deleted: attr(),
};

export default Licensees;
