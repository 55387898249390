import { createContext, useContext, useState } from "react";

export const AuthContext = createContext({});

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
    return useContext(AuthContext);
};

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});
    const [trustedDevice, setTrustDevice] = useState(JSON.parse(localStorage.getItem("trustDevice")) || false);

    return (
        <AuthContext.Provider value={{ auth, setAuth, trustedDevice, setTrustDevice }}>{children}</AuthContext.Provider>
    );
};
