import { Model, attr } from "redux-orm";

import { CREATE_ADMIN, DELETE_ADMIN, RESET_ADMIN, UPDATE_ADMIN, UPSERT_ADMIN } from "../../constants/redux";

class Admin extends Model {
    static reducer(action, admin) {
        switch (action.type) {
            case CREATE_ADMIN:
                admin.create(action.payload);
                break;
            case UPSERT_ADMIN:
                admin.upsert(action.payload);
                break;
            case UPDATE_ADMIN:
                admin.withId(action.payload.$id).update(action.payload);
                break;
            case DELETE_ADMIN:
                admin.withId(action.payload).delete();
                break;
            case RESET_ADMIN:
                admin.delete();
                break;
            default:
                break;
        }
    }
}
Admin.modelName = "Admin";

Admin.options = {
    idAttribute: "$id", // default idd
};
// attributen
Admin.fields = {
    $id: attr(),
    usersid: attr(),
    loginemail: attr(),
    firstname: attr(),
    infixname: attr(),
    lastname: attr(),
    evoit: attr(),
    emailverified: attr(),
    expdate: attr(),
    lastlogin: attr(),
    deleted: attr(),
    licensesid: attr(),
};

export default Admin;
