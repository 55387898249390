import { SET_THEME } from "../../constants/constants";

//zet de default theme 0 default andere nummer indien meer thema aanwezig is
export default function reducer(state = { currentTheme: 0 }, actions) {
    switch (actions.type) {
        case SET_THEME:
            return {
                ...state,
                currentTheme: actions.payload,
            };

        default:
            return state;
    }
}
