import { createSelector } from "redux-orm";

import scheme from "../models/orm";

const sortItems = (items) =>
    items?.sort((a, b) => {
        if (a.description.toUpperCase() < b.description.toUpperCase()) {
            return -1;
        }
        if (a.description.toUpperCase() > b.description.toUpperCase()) {
            return 1;
        }
        return 0;
    });

export const selectAllLicenses = createSelector(scheme, (session) => {
    return sortItems(session?.Licenses?.all()?.toRefArray())?.map((l) => ({ ...l, licenses: sortItems(l.licenses) }));
});

export const selectLicensesById = createSelector(
    scheme,
    (state, _licensesid) => selectAllLicenses(state),
    (_state, licensesid) => licensesid,
    (_state, licenses, licensesid) => {
        const onlyLincenses = licenses.flatMap((l) => l.licenses);
        return onlyLincenses?.find((l) => l.licensesid === licensesid);
    },
);
