import { SUCCESS, UPSERT_ADMIN } from "../../constants/redux";
import api from "../../services/api/apiService";
import { handleErrors } from "../../utilities/helpers";

// vraag de beheerders bij een specifieke licentie op
export const fetchAdmins = (id) => async (dispatch) => {
    try {
        const response = await api.get(`licenses/admins`, { licensesid: id });

        dispatch({
            type: UPSERT_ADMIN,
            payload: { admins: [...response.data.value], $id: Number(id) },
        });
        //   });
    } catch (error) {
        dispatch(error, handleErrors(error));
    }
};

// beheerder wijzigen/ toevoegen
export const updateAdmins = (items) => async (dispatch) => {
    try {
        const updateResponse = await api.post(`licenses/admins`, items);

        // toon succesmelding
        dispatch({
            type: SUCCESS,
            payload: "Admin toegevoegd " + updateResponse.data.value,
        });
        // vraag de beheerders opnieuw op
        dispatch(fetchAdmins(items.licensesid));
    } catch (error) {
        dispatch(error, handleErrors(error));
    }
};
