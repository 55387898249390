import { createSelector } from "redux-orm";

import scheme from "../models/orm";

export const selectAllDefapps = createSelector(scheme, (orm) => {
    return (
        orm.Defapps.all()
            .toModelArray()
            .sort((a, b) => {
                if (a.description.toUpperCase() < b.description.toUpperCase()) {
                    return -1;
                }
                if (a.description.toUpperCase() > b.description.toUpperCase()) {
                    return 1;
                }
                return 0;
            })
            // licentiebeheer uitfilteren
            .filter((app) => app.code !== "ELM")
            .map(({ $id, defappsid, code, description }) => ({
                description: description,
                defappsid: defappsid,
                code: code,
                $id: $id,
            }))
    );
});
export const selectDefappsByDefappsid = createSelector(
    scheme,
    (state, _defappsid) => selectAllDefapps(state),
    (_state, defappsid) => defappsid,
    (_state, apps, defappsid) => {
        return apps?.find((app) => app.defappsid === defappsid);
    },
);
