import { Model, attr } from "redux-orm";

import {
    CREATE_LICVERSIONS,
    DELETE_LICVERSIONS,
    RESET_LICVERSIONS,
    UPDATE_LICVERSIONS,
    UPSERT_LICVERSIONS,
} from "../../constants/redux";

class Licversions extends Model {
    static reducer(action, licversion) {
        switch (action.type) {
            case CREATE_LICVERSIONS:
                licversion.create(action.payload);
                break;
            case UPSERT_LICVERSIONS:
                action.payload.forEach((licensedetail) => {
                    licversion.upsert(licensedetail);
                });
                break;
            case UPDATE_LICVERSIONS:
                licversion.withId(action.payload.licversionsid).update(action.payload);
                break;
            case DELETE_LICVERSIONS:
                licversion.withId(action.payload).delete();
                break;
            case RESET_LICVERSIONS:
                licversion.delete();
                break;
            default:
                break;
        }
    }
}
Licversions.modelName = "Licversions";

Licversions.options = {
    idAttribute: "licversionsid", // default idd
};
// attributen
Licversions.fields = {
    $id: attr(),
    licversionsid: attr(),
    licensesid: attr(),
    code: attr(),
    description: attr(),
    tenantid: attr(),
    deleted: attr(),
    lastlogin: attr(),
    licapps: attr(),
};

export default Licversions;
