import { Model, attr } from "redux-orm";

import {
    CREATE_DEFMODULEGROUPS,
    DELETE_DEFMODULEGROUPS,
    RESET_DEFMODULEGROUPS,
    UPDATE_DEFMODULEGROUPS,
    UPSERT_DEFMODULEGROUPS,
} from "../../constants/redux";

class Defmodulegroups extends Model {
    static reducer(action, defmodulegroups) {
        switch (action.type) {
            case CREATE_DEFMODULEGROUPS:
                defmodulegroups.create(action.payload);
                break;
            case UPSERT_DEFMODULEGROUPS:
                action.payload.forEach((element) => {
                    defmodulegroups.upsert(element);
                });
                break;
            case UPDATE_DEFMODULEGROUPS:
                defmodulegroups.withId(action.payload.defmodulegroupsid).update(action.payload);
                break;
            case DELETE_DEFMODULEGROUPS:
                defmodulegroups.withId(action.payload).delete();
                break;
            case RESET_DEFMODULEGROUPS:
                defmodulegroups.delete();
                break;
            default:
                break;
        }
    }
}
Defmodulegroups.modelName = "Defmodulegroups";

Defmodulegroups.options = {
    idAttribute: "defmodulegroupsid", // default idd
};
// attributen
Defmodulegroups.fields = {
    $id: attr(),
    defmodulegroupsid: attr(),
    defappsid: attr(),
    description: attr(),
    code: attr(),
    defModules: attr(),
};

export default Defmodulegroups;
