export const DEFAULT_DATABASE = "ritplan";
// Thema
export const SET_THEME = "SET_THEME";

export const DEFAULT_DB_DATE = "1899-12-30";
export const DEFAULT_DB_DATE2 = "1970-01-01";

export const RITPLAN = "RIT";
export const LMSBACKOFFICE = "BAC";
export const REPOND = "REP";
export const LMSDRIVER = "DRI";

export const RIT_LAP = "RIT_LAP";
export const REP_LAP = "REP_LAP";
export const REP_USE = "REP_USE";
export const RIT_USE = "RIT_USE";
export const RIT_VEH = "RIT_VEH";
export const RIT_RDO = "RIT_RDO";
export const DRI_APP = "DRI_APP";
export const DRI_BCS = "DRI_BCS";
export const RIT_SIZ = "RIT_SIZ";
export const RIT_LIC = "RIT_LIC";
export const RIT_MAP = "RIT_MAP";
export const BAC_USE = "BAC_USE";
export const BAC_LAP = "BAC_LAP";
