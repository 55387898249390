import { SUCCESS } from "../../constants/redux";
import api from "../../services/api/apiService";
import { handleErrors } from "../../utilities/helpers";
import { fetchDefVersionTypes } from "./defVersionTypesActions";

// typemodule link toevoegen
export const updateDeftypeModule = (type, app) => async (dispatch) => {
    try {
        const updateResponse = await api.post(`definitions/deflictypemodule`, type);

        // toon succesmelding
        dispatch({
            type: SUCCESS,
            payload: " TypeModule gekoppeld " + updateResponse.data.value,
        });
        // haal alle types opnieuw op
        dispatch(fetchDefVersionTypes(app));
    } catch (error) {
        dispatch(handleErrors(error));
    }
};

// typemodule link verwijderen
export const deleteDeftypeModule = (type, app) => async (dispatch) => {
    try {
        const response = await api.delete(`definitions/deflictypemodule`, type);
        // toon succesmelding
        dispatch({
            type: SUCCESS,
            payload: " TypeModule losgekoppeld " + response.data.value,
        });
        // haal alle types opnieuw op
        dispatch(fetchDefVersionTypes(app));
    } catch (error) {
        dispatch(handleErrors(error));
    }
};
