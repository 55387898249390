import { green, grey } from "@mui/material/colors";

import { colorGreen, colorOrange, colorRed, disabledColor, mainColor, secondaryColor } from "../constants/colors";

const evoitVariant = {
    name: "Dark",
    palette: {
        primary: {
            main: mainColor,
            contrastText: "#FFFFFF",
        },
        secondary: {
            main: secondaryColor,
            contrastText: "#FFFFFF",
        },
        warning: {
            main: colorOrange,
            contrastText: "#FFFFFF",
        },
        success: {
            main: colorGreen,
            light: "#4caf50",
            contrastText: "#FFFFFF",
        },
        error: {
            main: colorRed,
            contrastText: "#FFFFFF",
        },
        info: {
            main: "#64b5f6",
            contrastText: "#FFFFFF",
        },
        disabled: {
            main: disabledColor,
            contrastText: "#FFFFFF",
        },
    },
    header: {
        color: mainColor,
        //color:"#FFFFFF",
        background: "#FFFFFF",
        search: {
            color: mainColor,
        },
        indicator: {
            background: mainColor,
        },
    },
    sidebar: {
        color: "#FFFFFF",
        background: mainColor,

        header: {
            color: grey[200],
            background: mainColor,
            brand: {
                color: "#FFFFFF",
            },
        },
        footer: {
            color: grey[200],
            background: mainColor,
            online: {
                background: green[500],
            },
        },
        category: {
            fontWeight: 500,
        },
        badge: {
            color: "#FFFFFF",
            background: "#FFFFFF",
        },
    },
    body: {
        background: "#e8e8e8",
    },
};

const variants = [evoitVariant];

export default variants;
