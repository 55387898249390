const getPrimaryAndSecondaryColor = () => {
    const platform = window.location.hostname;
    const primary = "#0057A0";
    const secondary = "#004A87";
    const staging = "#800000";
    const local = "#708090";

    if (platform?.includes("staging-licenses.evo-it")) {
        return { primary: staging, secondary: staging };
    } else if (platform?.includes("licenses.evo-it")) {
        return { primary: primary, secondary: secondary };
    } else {
        return { primary: local, secondary: local };
    }
};

export const mainColor = getPrimaryAndSecondaryColor().primary;
export const secondaryColor = getPrimaryAndSecondaryColor().secondary;
export const colorRed = "#C62828";
export const colorGreen = "#388E3C";
export const colorOrange = "#ED6C02";
export const colorYellow = "#ffdf00";
export const disabledColor = "#d7d9d9";
export const disabledColor2 = "#e7e7e8";
