import { Model, attr } from "redux-orm";

import {
    DELETE_LICENSEMODULES,
    RESET_LICENSEMODULES,
    UPDATE_LICENSEMODULES,
    UPSERT_LICENSEMODULES,
} from "../../constants/redux";

class LicenseModules extends Model {
    static reducer(action, modules) {
        switch (action.type) {
            case UPSERT_LICENSEMODULES:
                action.payload.forEach((module) => {
                    modules.upsert({
                        ...module,
                        licensesid: action.licensesid,
                        uid: Number(`${action.licensesid}${module.defmodulesid}`),
                    });
                });
                break;
            case UPDATE_LICENSEMODULES:
                modules.withId(action.payload.uid).update(action.payload);
                break;
            case DELETE_LICENSEMODULES:
                modules.withId(action.payload).delete();
                break;
            case RESET_LICENSEMODULES:
                modules.delete();
                break;
            default:
                break;
        }
    }
}
LicenseModules.modelName = "LicenseModules";

LicenseModules.options = {
    //idAttribute: "licappsid" + "-" + "defmodulesid", // default idd
    idAttribute: "uid", // default idd
};
// attributen
LicenseModules.fields = {
    uid: attr(),
    $id: attr(),
    code: attr(),
    activationdate: attr(),
    defmodulesid: attr(),
    defoptionsid: attr(),
    expdate: attr(),
    exptestdate: attr(),
    licappsid: attr(),
    licensesid: attr(),
    value: attr(),
};

export default LicenseModules;
