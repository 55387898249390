import { DELETE_MODULES, SUCCESS, UPSERT_MODULES } from "../../constants/redux";
import api from "../../services/api/apiService";
import { handleErrors } from "../../utilities/helpers";
import { fetchLicenseModules } from "./licenseModulesActions";

// haal alle modules op
export const fetchModules = (id, licversionsid) => async (dispatch) => {
    try {
        const response = await api.get(`licenses/modules`, { licappsid: id });

        dispatch({
            type: UPSERT_MODULES,
            payload: response.data.value,
            licversionsid: licversionsid,
        });
    } catch (error) {
        dispatch(handleErrors(error));
    }
};

// update de licentie-/modules
export const updateModules = (module, licversion, succesmelding) => async (dispatch) => {
    try {
        const updateResponse = await api.post(`licenses/modules`, module);

        // toon succesmelding
        succesmelding &&
            dispatch({
                type: SUCCESS,
                payload: "Module gewijzigd " + updateResponse.data.value,
            });

        // haal alle licentie modules
        dispatch(fetchLicenseModules(module.licensesid));

        // licappsid niet leeg? haal alle modules bij een licapps
        if (module.licappsid !== undefined) {
            dispatch(fetchModules(module.licappsid, licversion));
        }
    } catch (error) {
        dispatch(handleErrors(error));
    }
};

// verwijderd module
export const deleteModules = (licapp, defmodule) => async (dispatch) => {
    try {
        await api.delete(`licenses/modules`, {
            defmodulesid: defmodule,
            licappsid: licapp,
        });

        //verwijderd alleen de geselecteerde module in de orm
        dispatch({
            type: DELETE_MODULES,
            payload: Number(String(licapp) + String(defmodule)),
        });

        // toon succesmelding
        dispatch({
            type: SUCCESS,
            payload: "Module verwijderd " + defmodule,
        });
        //dispatch(fetchModules(licapp));
    } catch (error) {
        dispatch(handleErrors(error));
    }
};
