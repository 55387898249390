import { groupBy } from "lodash";
import { createSelector } from "redux-orm";

import scheme from "../models/orm";

export const selectAllAdmin = createSelector(scheme, (orm) => {
    return groupBy(
        orm.Admin.all()
            .toModelArray()
            .flatMap((items) => items.admins),
        (item) => item.licensesid,
    );
});
