import { groupBy } from "lodash";
import { createSelector } from "redux-orm";

import scheme from "../models/orm";

export const selectAllLicenseModules = createSelector(scheme.LicenseModules);

export const selectAllLicenseModulesByLicensesId = createSelector(scheme, (orm) => {
    return groupBy(
        orm.LicenseModules.all()
            .toModelArray()
            .map(
                ({
                    $id,
                    licensesid,
                    licappsid,
                    code,
                    defmodulesid,
                    value,
                    defoptionsid,
                    activationdate,
                    exptestdate,
                    expdate,
                    uid,
                }) => ({
                    uid: uid,
                    $id: $id,
                    code: code,
                    licensesid: licensesid,
                    licappsid: licappsid,
                    defmodulesid: defmodulesid,
                    value: value,
                    defoptionsid: defoptionsid,
                    activationdate: activationdate,
                    exptestdate: exptestdate,
                    expdate: expdate,
                }),
            ),
        (it) => it.licensesid,
    );
});
