import { groupBy } from "lodash";
import { createSelector } from "redux-orm";

import scheme from "../models/orm";

export const selectAllDefOptions = createSelector(scheme.Defoptions);

export const selectAllDefOptionsByDefmodulesid = createSelector(scheme, (orm) => {
    return groupBy(
        orm.Defoptions.all()
            .toModelArray()
            .map(({ $id, defoptionsid, defmodulesid, code, description, value }) => ({
                code: code,
                description: description,
                defmodulesid: defmodulesid,
                $id: $id,
                value: value,
                defoptionsid: defoptionsid,
            })),
        (it) => it.defmodulesid,
    );
});
