import { groupBy } from "lodash";
import { createSelector } from "redux-orm";

import scheme from "../models/orm";

export const selectAllDefModuleGroups = createSelector(scheme.Defmodulegroups);

export const selectAllDefModuleGroupsByDefappsid = createSelector(scheme, (orm) => {
    return groupBy(
        orm.Defmodulegroups.all()
            .toModelArray()
            .sort((a, b) => {
                if (a.description.toUpperCase() < b.description.toUpperCase()) {
                    return -1;
                }
                if (a.description.toUpperCase() > b.description.toUpperCase()) {
                    return 1;
                }
                return 0;
            })
            .map((defmodule) => defmodule),
        (it) => it.defappsid,
    );
});
