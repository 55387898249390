import { useMediaQuery, useTheme } from "@mui/material";

import dayjs from "dayjs";

import { DEFAULT_DB_DATE, DEFAULT_DB_DATE2 } from "../constants/constants";
import { ERROR } from "../constants/redux";

function randomBetween(a, b) {
    const max = Math.max(a, b);
    const min = Math.min(a, b);
    return Math.floor(Math.random() * (max - min) + min);
}

// Get an integer hashCode for the given String
function hashCode(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash;
    }
    return hash;
}

// Get a random function for the given Integer seed
// https://stackoverflow.com/a/47593316/15469537
function mulberry32(seed) {
    return function () {
        let t = (seed += 0x6d2b79f5);
        t = Math.imul(t ^ (t >>> 15), t | 1);
        t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
        return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
    };
}

export function randomColor(firstColor, secondColor, seed) {
    const first = firstColor.toUpperCase().substring(1, secondColor.length);
    const second = secondColor.toUpperCase().substring(1, firstColor.length);
    const scale = "0123456789ABCDEF";
    let color = "#";

    // Seeded random function, for consistent random colors after reloads etc.
    let randomGenerator;
    if (seed) {
        randomGenerator = mulberry32(hashCode(seed));
    }

    for (let i = 0; i < first.length && i < second.length; i++) {
        const random = randomBetween(scale.indexOf(first[i]), scale.indexOf(second[i]), randomGenerator);
        color += scale[random];
    }
    return color;
}

//converteer datum van 'Tue Sep 08 2020 10:37:57 GMT+0200 (Midden-Europese zomertijd)' naar '2020-09-08'
export const formatDateToYYYYMMDD = (newDate) => {
    if (!newDate) {
        return "";
    }
    return dayjs(newDate).format("YYYY-MM-DD");
};

//dag van vandaag
export const today = formatDateToYYYYMMDD(dayjs());

export const handleErrors = (error) => (dispatch) => {
    const status = error?.response?.status;

    if (error.response) {
        /*
         * Bepaal welke melding getoond moet worden indien response is aanwezig
         * Toon de error melding van de response. Dit wordt straks uitgebreid met translation obv response.code
         */
        if (status !== 401) {
            dispatch({
                type: ERROR,
                payload: error.response?.data?.error?.message || error.response?.data,
            });
        }
    } else {
        dispatch({ type: ERROR, payload: error.message });
    }
};

export const getAppIdByCode = (apps, code) => apps?.find((app) => app.code === code)?.defappsid;

export const getDetailsByCode = (apps, code) => apps?.find((app) => app.code === code)?.value;
/*
 * Dit is een functie die een boolean terug geeft of je in mobiel of desktop modus zit.
 * Naam geeft aan dat dit een component is, hoewel dit een normale functie is.
 * Dit is gedaan om de useTheme te implementeren, want dit kun je ALLEEN bij een component gebruiken.
 */
export function IsMobile() {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.down("md"));
}

export const isDefaultDate = (date) => date === DEFAULT_DB_DATE || date === DEFAULT_DB_DATE2;
export const getDate = (date) => (isDefaultDate(date) ? "" : date);
